<template>
	<v-sheet class="inventory" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex class="d-flex align-items-center flex-wrap">
							<p class="my-auto font-level-3-bold" style="font-size: 20px">
								<ShowValue :object="product" object-key="name" label="Product Name"></ShowValue>
							</p>
							<ShowBarcode
								:object="product"
								object-key="barcode"
								tooltip
								tooltip-text="product #"
								click-to-copy
								label="barcode"
							></ShowBarcode>
						</v-flex>
						<v-flex md7 class="text-right">
							<template v-if="false && inventory.retired">
								<v-btn
									v-on:click="doAction('stock-history')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-history</v-icon>Stock History</v-btn
								>
								<v-btn
									v-on:click="doAction('print-label')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-printer</v-icon>Print Label</v-btn
								>
							</template>
							<template v-else>
								<v-btn
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									v-on:click="doAction('edit')"
								>
									<v-icon small left>mdi-pencil</v-icon>Edit
								</v-btn>
								<v-btn @click="openPriceDialog" color="blue darken-4 white--text ml-2" dense tile
									>Manage Price</v-btn
								>
								<v-btn
									v-on:click="backToListing('product')"
									color="grey lighten-3"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
								>
								<v-menu v-if="false" offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
											><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
										>
									</template>
									<v-list>
										<v-list-item v-if="false" v-on:click="doAction('stock-history')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-history</v-icon>
												Stock History</v-list-item-title
											>
										</v-list-item>
										<v-list-item v-if="false" v-on:click="doAction('print-label')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-printer</v-icon>
												Print Label</v-list-item-title
											>
										</v-list-item>
										<v-list-item
											v-if="inventory.retired == 0 && getPermission('inventory:update')"
											v-on:click="doAction('edit')"
										>
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('inventory:create')" v-on:click="doAction('duplicate')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-content-duplicate</v-icon>
												Clone
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('inventory:delete')" v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
										<!-- Used In responsive -->
										<template v-if="deviceWidth < 1360.98">
											<template v-if="!inventory.retired && inventory.initial_stock > 0">
												<v-list-item v-if="getPermission('checkout:create')" v-on:click="doAction('checkout')">
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-share-outline</v-icon>
														Issue
													</v-list-item-title>
												</v-list-item>
												<v-list-item
													v-if="getPermission('inventory:update')"
													v-on:click="doAction('retire-stock')"
												>
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
														Retire Stock
													</v-list-item-title>
												</v-list-item>
												<v-list-item v-if="getPermission('inventory:update')" v-on:click="doAction('retire')">
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
														Retire
													</v-list-item-title>
												</v-list-item>
											</template>
										</template>
									</v-list>
								</v-menu>
								<template v-if="false && deviceWidth > 1360.02">
									<template v-if="!inventory.retired && inventory.initial_stock > 0">
										<v-btn
											v-if="getPermission('checkout:create')"
											v-on:click="doAction('checkout')"
											color="blue darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-outline</v-icon>Issue
										</v-btn>
										<v-btn
											v-if="getPermission('inventory:update')"
											v-on:click="doAction('retire-stock')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-cancel</v-icon>Retire Stock</v-btn
										>
										<v-btn
											v-if="getPermission('inventory:update')"
											v-on:click="doAction('retire')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-cancel</v-icon>Retire</v-btn
										>
									</template>
									<v-btn
										v-if="false"
										v-on:click="doAction('reserve')"
										color="blue darken-4 text-white"
										class="ml-2"
										depressed
										tile
										><v-icon small left>mdi-calendar-check</v-icon>Reserve</v-btn
									>
								</template>
								<v-btn
									v-if="inventory.retired == 0 && getPermission('inventory:update')"
									v-on:click="doAction('add-stock')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-playlist-plus</v-icon>Add Stock</v-btn
								>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="product.image"></ImageTemplate>
						</div>
						<div class="width-100 d-flex">
							<!-- <div class="width-22" style="height: auto">
								<div class="mt-2">
									<span class="fw-600">Serial Number : </span>
									<span class="fw-500 no-wrap">
										<v-chip
											label
											color="blue lighten-4  text--darken-4"
											v-if="false && product.serial_number"
										>
											{{ product.serial_number }}
										</v-chip>
										<ShowValue :object="product" object-key="serial_number" label="Serial Number"></ShowValue>
									</span>
								</div>
								<div class="mt-2">
									<span class="fw-600"> Category : </span>
									<v-chip
										label
										color="blue lighten-4  text--darken-4 "
										v-if="false && product.category"
									>
										<span class="fw-500"> {{ product.category }}</span>
									</v-chip>
									<ShowValue :object="product" object-key="category" label="category"></ShowValue>
								</div>
								<div class="mt-2">
									<span class="fw-600"> Product Type : </span>
									<v-chip
										label
										color="blue lighten-4  text--darken-4 text-capitilize"
										v-if="false && product.type"
									>
										<span class="fw-500"> {{ product.type }}</span>
									</v-chip>
									<ShowValue :object="product" object-key="type" label="type"></ShowValue>
								</div>
							</div> -->
							<div class="width-24 product-detail-overview" style="height: auto">
								<div class="font-level-4-bold px-4 py-1" style="background-color: rgb(193 215 237 / 87%)">
									Overview
								</div>
								<div class="px-4 mb-2">
									<div class="mt-4">
										<span class="fw-600 mr-2 font-level-3-bold">Serial Number :</span>
										<span class="fw-500 no-wrap font-level-3">
											<!-- <v-badge color="blue" :content="`${product.initial_stock ? product.initial_stock : 0}`">
										</v-badge> -->
											<ShowValue
												:object="product"
												object-key="serial_number"
												label="Serial Number"
											></ShowValue>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600"> Category : </span>
										<span class="fw-500 no-wrap text--darken-4">
											<ShowValue :object="product" object-key="category" label="category"></ShowValue>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600"> Product Type : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="product" object-key="type" label="type"></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="width-24 mx-6 product-detail-price" style="height: auto">
								<div class="font-level-4-bold px-4 py-1" style="background-color: rgb(241 230 202)">
									Price
								</div>
								<div class="px-4 mb-2">
									<div class="mt-4">
										<span class="fw-600 mr-2 font-level-3-bold">Selling cost : </span>
										<span class="fw-500 no-wrap font-level-3">
											<!-- <v-badge color="blue" :content="`${product.initial_stock ? product.initial_stock : 0}`">
										</v-badge> -->
											<ShowPrice :object="product" object-key="selling_cost" label="selling cost"> </ShowPrice>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600"> Discounted price : </span>
										<span class="fw-500 no-wrap text--darken-4">
											<ShowPrice
												:object="product"
												object-key="discount_price"
												label="discount price"
											></ShowPrice>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600">Price Currency: </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="product"
												object-key="unit_price_currency"
												label="unit price currency"
											></ShowValue>
										</span>
									</div>
									<!-- <div class="mt-2">
										<span class="fw-600"> Unit Price: </span>
										<span class="fw-500 no-wrap">
											<show-price
												:object="product"
												object-key="unit_price"
												label="unit price"
											></show-price>
										</span>
									</div> -->
								</div>
							</div>
							<div class="width-24 product-detail-stock" style="height: auto">
								<div class="font-level-4-bold px-4 py-1" style="background-color: #7cecdd66">Stock</div>

								<div class="px-4 mb-2">
									<div class="mt-4">
										<span class="fw-600 mr-2">Unit: </span>
										<span class="fw-500 no-wrap">
											<!-- <ShowPrice :object="product" object-key="unit_price" label="unit price"> </ShowPrice> -->
											<v-badge v-if="product.unit" color="cyan lighten-2" :content="product.unit"> </v-badge>
											<em v-else class="text-muted">No unit</em>
										</span>
									</div>
									<!-- <div class="mt-2">
										<span class="fw-600 mr-2 font-level-3-bold">Initial Stock : </span>
										<span class="font-level-3 no-wrap text-h5">
											<v-badge color="blue" :content="`${product.initial_stock ? product.initial_stock : 0}`">
											</v-badge>
										</span>
									</div> -->
									<div class="mt-2">
										<span class="fw-600 mr-2 font-level-3-bold">Available Stock : </span>
										<span class="font-level-3f no-wrap text-h5">
											<v-badge
												color="green"
												:content="`${product.available_stock ? product.available_stock : 0}`"
											>
											</v-badge>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600">Reorder level : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="product" object-key="reorder_level" label="Reorder Level">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="width-24 ml-6 product-detail-created-at" style="max-width: 33%; height: auto">
								<div class="font-level-4-bold px-4 py-1" style="background-color: rgb(228 244 223)">
									Created At
								</div>
								<div class="px-4 mb-2">
									<div class="mt-4 d-flex">
										<div class="fw-600 mr-2">Created date :</div>
										<div class="fw-500" v-if="product.added_at">
											<div>
												{{ MomentJS(product.added_at).format("DD/MM/YYYY") }}
											</div>
											<div>
												{{ MomentJS(product.added_at).format("h:mm A") }}
											</div>
										</div>
										<em v-else class="text-muted">no created date</em>
									</div>
									<div class="mt-2">
										<span class="fw-600 mr-2">Created By : </span>
										<span class="fw-500">
											<ShowValue :object="product" object-key="added_by" label="created by"> </ShowValue>
										</span>
									</div>
									<div class="mt-2 d-flex">
										<div class="fw-600 mr-2">Updated date :</div>
										<div class="fw-500" v-if="product.updated_at">
											<div>
												{{ MomentJS(product.updated_at).format("DD/MM/YYYY") }}
											</div>
											<div>
												{{ MomentJS(product.updated_at).format("h:mm A") }}
											</div>
										</div>
										<em v-else class="text-muted">no updated date</em>
									</div>
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated By : </span>
										<span class="fw-500">
											<ShowValue :object="product" object-key="updated_by" label="updated by"> </ShowValue>
										</span>
									</div>
									<div class="mt-2">
										<span class="fw-600"> Effective From : </span>
										<span class="fw-500 no-wrap text--darken-4">
											<span class="fw-500" v-if="product.effective_from">
												{{ formatDate(product.effective_from) }}
											</span>
											<em v-else class="text-muted">effective from</em>
										</span>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="w-100 pr-3">
							<div
								v-if="false && inventory.pico_barcode && inventory.barcode_image"
								style="max-width: 300px"
							>
								<div class="d-inline-block text-center">
									<img
										style="max-width: 100%; height: 30px"
										:src="inventory.barcode_image"
										:alt="inventory.pico_barcode"
									/>
									<pre class="mb-0 barcode-text bold-700">{{ inventory.pico_barcode }}</pre>
								</div>
							</div>
							<div class="d-flex flex-wrap">
								<div class="width-66 d-flex flex-wrap">
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600">Serial Number : </span>
											<span class="fw-500 no-wrap">
												<v-chip
													label
													color="blue lighten-4  text--darken-4"
													v-if="false && product.serial_number"
												>
													{{ product.serial_number }}
												</v-chip>
												<ShowValue
													:object="product"
													object-key="serial_number"
													label="Serial Number"
												></ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600"> Product Type : </span>
											<v-chip
												label
												color="blue lighten-4  text--darken-4 text-capitilize"
												v-if="false && product.type"
											>
												{{ product.type }}
											</v-chip>
											<ShowValue :object="product" object-key="type" label="type"></ShowValue>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600"> Category : </span>
											<v-chip
												label
												color="blue lighten-4  text--darken-4"
												v-if="false && product.category"
											>
												{{ product.category }}
											</v-chip>
											<ShowValue :object="product" object-key="category" label="category"></ShowValue>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600">Reorder level : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="product" object-key="reorder_level" label="Reorder Level">
												</ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600"> Selling cost : </span>
											<span class="fw-500 no-wrap">
												<ShowPrice :object="product" object-key="selling_cost" label="selling cost">
												</ShowPrice>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600"> Effective From : </span>
											<span class="fw-500 no-wrap  text--darken-4">
												<span class="fw-500" v-if="product.effective_from">
													{{ formatDate(product.effective_from) }}
												</span>
												<em v-else class="text-muted">effective from</em>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600">Unit Price : </span>
											<span class="fw-500 no-wrap">
												<ShowPrice :object="product" object-key="unit_price" label="unit price"> </ShowPrice>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600"> Discounted price : </span>
											<span class="fw-500 no-wrap  text--darken-4">
												<ShowPrice
													:object="product"
													object-key="discount_price"
													label="discount price"
												></ShowPrice>
											</span>
										</div>
									</div>
								</div>
								<div class="width-33">
									<div class="width-100">
										<div class="mt-2">
											<span class="fw-600 mr-2">Created date : </span>
											<span class="fw-500" v-if="product.added_at">
												{{ formatDateTime(product.added_at) }}
											</span>
											<em v-else class="text-muted">no created date</em>
										</div>
									</div>
									<div class="width-100">
										<div class="mt-2">
											<span class="fw-600 mr-2">Created By : </span>
											<span class="fw-500">
												<ShowValue :object="product" object-key="added_by" label="created by"> </ShowValue>
											</span>
										</div>
									</div>
									<div class="width-100">
										<div class="mt-2">
											<span class="fw-600 mr-2">Updated date : </span>
											<span class="fw-500" v-if="product.updated_at">
												{{ formatDateTime(product.updated_at) }}
											</span>
											<em v-else class="text-muted">no updated date</em>
										</div>
									</div>
									<div class="width-100">
										<div class="mt-2">
											<span class="fw-600 mr-2">Updated By : </span>
											<span class="fw-500">
												<ShowValue :object="product" object-key="updated_by" label="updated by"> </ShowValue>
											</span>
										</div>
									</div>
								</div>
								<div class="width-100 d-flex flex-wrap">
									<div class="width-33">
										<div class="mt-2">
											<span class="fw-600 mr-2">Initial Stock : </span>
											<span class="fw-500 no-wrap">
												<v-badge color="blue" :content="`${product.initial_stock ? product.initial_stock : 0}`">
												</v-badge>
											</span>
										</div>
									</div>
									<div class="width-33">
										<div class="mt-2">
											<span class="fw-600 mr-2">Available Stock : </span>
											<span class="fw-500 no-wrap text-h5">
												<v-badge
													color="green"
													:content="`${product.available_stock ? product.available_stock : 0}`"
												>
												</v-badge>
											</span>
										</div>
									</div>
									<div class="width-33">
										<div class="mt-2">
											<span class="fw-600">Unit : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="product" object-key="unit" label="unit"> </ShowValue>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div> -->
						<div class="w-20 pr-3" v-if="false && inventory.unit">
							<div class="mt-2">
								<span class="fw-600">Pico Barcode : </span>
								<span class="fw-500">
									<Barcode circle :src="inventory.unit"></Barcode>
								</span>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="inventoryTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#suppliers">
						<v-icon small left>mdi-account-group</v-icon> Suppliers ({{
							product.supplier_relation ? product.supplier_relation.length : 0
						}})</v-tab
					>
					<v-tab href="#pricing">
						<v-icon small left>mdi-currency-usd-circle-outline</v-icon> price ({{
							product.pricing_logs_history ? product.pricing_logs_history.length : 0
						}})</v-tab
					>
					<v-tab href="#quotation">
						<v-icon small left>mdi-receipt</v-icon> Quotation History ({{
							quotationListing?.tbody ? quotationListing?.tbody.length : 0
						}})</v-tab
					>
					<v-tab href="#purchase-order-moment-history">
						<v-icon small left>mdi-receipt</v-icon> Purchase Order Moment History
					</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
					<v-tab href="#quantity-by-location" v-if="false">
						<v-icon small left>mdi-map-marker-outline</v-icon> Quantity by Location</v-tab
					>
					<v-tab v-if="false && getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab v-if="false" href="#reservation">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
					<v-tab v-if="false" href="#retire_stock_records">
						<v-icon small left>mdi-calendar-check</v-icon> Retire Stock Records</v-tab
					>
					<v-tab href="#related-service" v-if="false">
						<v-icon small left>mdi-hammer-wrench</v-icon> Related Services</v-tab
					>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders</v-tab
					>
					<v-tab v-if="false" href="#file">
						<v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab
					>
					<v-tab v-if="false" href="#comment">
						<v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab
					>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support</v-tab
					>
				</v-tabs>

				<v-tabs-items v-model="inventoryTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<div class="w-100 mt-4 d-flex">
									<div class="width-50 mr-4 product-detail-description-box">
										<div class="font-level-4-bold px-4 py-3" style="background-color: rgb(237 224 224)">
											Dimension
										</div>
										<div class="px-4 mb-4">
											<div class="mt-4">
												<span class="fw-600"> Weight : </span>
												<span class="fw-500 no-wrap text--darken-4">
													<ShowValue :object="product" object-key="weight" label="weight"></ShowValue>
													<span v-if="product"> {{ product.weight_unit }} </span>
												</span>
											</div>
											<div class="mt-2">
												<span class="fw-600"> Length : </span>
												<span class="fw-500 no-wrap text--darken-4">
													<ShowValue :object="product" object-key="length" label="length"></ShowValue>
													<!-- <template v-if="product && product.length"> cm </template> -->
													<!-- <template> {{ product.length_unit }} </template> -->
													<span v-if="product && product.length_unit"> {{ product.length_unit }} </span>
												</span>
											</div>
											<div v-if="product.type !== 'piping'" class="mt-2">
												<span class="fw-600">Height : </span>
												<span class="fw-500 no-wrap text--darken-4">
													<ShowValue :object="product" object-key="height" label="height"></ShowValue>
													<!-- <template v-if="product && product.height"> cm </template> -->
													<span v-if="product && product.height_unit"> {{ product.height_unit }} </span>
												</span>
											</div>
											<div v-if="product.type !== 'piping'" class="mt-2">
												<span class="fw-600">Width : </span>
												<span class="fw-500 no-wrap text--darken-4">
													<ShowValue :object="product" object-key="width" label="width"></ShowValue>

													<span v-if="product && product.width_unit"> {{ product.width_unit }} </span>
												</span>
											</div>
											<div class="mt-2">
												<span class="fw-600 mr-2">Dimension Standard: </span>
												<span class="fw-500 no-wrap">
													<ShowValue
														:object="product"
														object-key="dimension_standard"
														label="Dimension Standard"
													></ShowValue>
												</span>
											</div>
											<template v-if="product.type === 'piping'">
												<div class="mt-2">
													<span class="fw-600">Diameter : </span>
													<span class="fw-500 no-wrap text--darken-4">
														<ShowValue :object="product" object-key="diameter" label="length"></ShowValue>
														<!-- <template v-if="product && product.diameter"> cm </template> -->
														<template v-if="product && product.diameter_unit">
															{{ product.diameter_unit }}
														</template>
													</span>
												</div>
											</template>
											<!-- <template v-else>
												<div class="mt-2">
													<span class="fw-600">Width : </span>
													<span class="fw-500 no-wrap  text--darken-4">
														<ShowValue :object="product" object-key="diameter" label="length"></ShowValue>
														<template v-if="product && product.reduced_diameter"> cm </template>
													</span>
												</div>
											</template> -->
											<div v-if="product.type == 'piping'" class="mt-2">
												<span class="fw-600"> Reduced Diameter : </span>
												<span class="fw-500 no-wrap text--darken-4">
													<ShowValue :object="product" object-key="reduced_diameter" label="length"></ShowValue>
													<template v-if="product && product.reduced_diameter_unit">
														{{ product.reduced_diameter_unit }}
													</template>
												</span>
											</div>
										</div>
									</div>
									<div class="width-50 product-detail-product-detail-box">
										<div class="font-level-4-bold px-4 py-3" style="background-color: #d3c1e6">
											Product Detail
										</div>
										<div class="px-4 mb-4">
											<div class="mt-4">
												<span class="fw-600 mr-2">Quote ref no: </span>
												<span class="fw-500 no-wrap">
													<ShowValue :object="product" object-key="quote_ref_no" label="quote ref no.">
													</ShowValue>
												</span>
											</div>
											<div class="mt-2">
												<span class="fw-600"> Material Standard : </span>
												<span class="fw-500">
													<ShowValue :object="product" object-key="material_standard" label="Material Standard">
													</ShowValue>
												</span>
											</div>
											<div class="mt-2">
												<span class="fw-600"> Manufacturer : </span>
												<span class="fw-500 no-wrap text--darken-4">
													<ShowValue :object="product" object-key="manufacturer" label="Manufacturer">
													</ShowValue>
												</span>
											</div>
											<div class="mt-2">
												<span class="fw-600"> Description : </span>
												<span class="fw-500 break-spaces">
													<ShowValue :object="product" object-key="description" label="description"> </ShowValue>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="suppliers">
						<SupplierListing
							v-if="inventoryTab == 'suppliers' && uuid"
							class="mx-4"
							type="inventory"
							:type-uuid="uuid"
						></SupplierListing>
					</v-tab-item>
					<v-tab-item value="pricing" eager>
						<ProductPriceListing
							class="mx-4"
							type="inventory"
							:type-uuid="uuid"
							:price-data="product.pricing_logs"
							:price-data-listing="product.pricing_logs_history"
							:outSideSaveDialog="outSideSaveDialog"
							@dialogClosed="outSideSaveDialog = false"
							v-on:success="getInventory()"
						>
						</ProductPriceListing>
					</v-tab-item>
					<v-tab-item value="quotation">
						<ProductQuotationListing
							class="mx-4"
							v-if="inventoryTab == 'quotation' && uuid"
							:quotationListing="quotationListing"
							@update-product="(page) => getProductQuotation(uuid, page)"
						>
						</ProductQuotationListing>
					</v-tab-item>
					<v-tab-item value="purchase-order-moment-history">
						<ProductPOMomentHistory
							class="mx-4"
							v-if="inventoryTab == 'purchase-order-moment-history' && uuid"
						>
						</ProductPOMomentHistory>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="inventoryTab == 'event' && uuid"
							class="mx-4"
							type-text="Product"
							type="product"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="retire_stock_records" v-if="false">
						<ItemRetirementsListing
							v-if="inventoryTab == 'retire_stock_records'"
							class="mx-4"
							type-text="Inventory"
							type="inventory"
							:uuid="uuid"
						></ItemRetirementsListing>
					</v-tab-item>
					<v-tab-item value="quantity-by-location" v-if="false">
						<QuantityLocation
							v-if="inventoryTab == 'quantity-by-location'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></QuantityLocation>
					</v-tab-item>
					<v-tab-item value="transaction" v-if="false">
						<template v-if="inventoryTab == 'transaction'">
							<TransactionListing relation-type="inventory" :relation-type-id="inventory.id">
							</TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="reservation" v-if="false">
						<ReservationListing
							v-if="inventoryTab == 'reservation'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="related-service" v-if="false">
						<RelatedServiceTemplate
							v-if="inventoryTab == 'related-service'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></RelatedServiceTemplate>
					</v-tab-item>
					<v-tab-item value="work-order" v-if="false">
						<WorkOrderListing
							v-if="inventoryTab == 'work-order'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file" v-if="false">
						<Files
							v-if="inventoryTab == 'file'"
							class="mx-4"
							type-text="Perishables Files"
							type="inventory"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment" v-if="false">
						<Comments
							v-if="inventoryTab == 'comment'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support" v-if="false">
						<SupportTemplate
							v-if="inventoryTab == 'support'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Perishables"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!inventory.retired
						? 'To preserve associated records, you could retire the Perishables instead.'
						: null
				"
			>
				<template v-if="!inventory.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<template v-if="false && retireDialog">
				<RetireTemplate
					type="Inventory"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="goBack()"
					v-on:close="retireDialog = false"
					:retire-dialog="retireDialog"
				></RetireTemplate>
			</template>
			<template v-if="false && retireStockDialog">
				<RetireStockTemplate
					type="Inventory"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="goBack()"
					v-on:close="retireStockDialog = false"
					:retire-dialog="retireStockDialog"
				></RetireStockTemplate>
			</template>
			<template v-if="false && reservationDialog">
				<ReservationTemplate
					type="Inventory"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="
						reservationDialog = false;
						getInventory();
					"
					v-on:close="reservationDialog = false"
					:reservation-dialog="reservationDialog"
				></ReservationTemplate>
			</template>
			<AddStockTemplate v-if="false" v-on:success="getInventory"></AddStockTemplate>
			<template v-if="false && checkoutDialog">
				<CheckoutTemplate
					type="Perishable"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="
						checkoutDialog = false;
						getInventory();
					"
					v-on:close="checkoutDialog = false"
					:checkout-dialog="checkoutDialog"
				></CheckoutTemplate>
			</template>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import QuantityLocation from "@/view/components/QuantityLocation";
import ReservationListing from "@/view/components/ReservationListing";
import TransactionListing from "@/view/components/TransactionListing";
import EventListing from "@/view/components/EventListing";
import SupplierListing from "@/view/components/SupplierListing";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import { GetInventory } from "@/core/lib/inventory.lib";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import AddStockTemplate from "@/view/components/AddStockTemplate";
import { EventBus } from "@/core/event-bus/event.bus";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import RetireTemplate from "@/view/components/RetireTemplate";
import RetireStockTemplate from "@/view/components/RetireStockTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import RelatedServiceTemplate from "@/view/components/RelatedServiceTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import ProductQuotationListing from "@/view/components/ProductQuotationListing";
import ProductPOMomentHistory from "@/view/components/ProductPOMomentHistory";

import ItemRetirementsListing from "@/view/components/ItemRetirementsListing";
import ShowBarcode from "@/view/components/ShowBarcode";
import ProductPriceListing from "@/view/components/ProductPriceListing";
// import ApiService from "@/core/services/api.service";
// import Dialog from "@/view/components/Dialog";
// import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import { cloneDeep } from "lodash";
// import moment from "moment";
import MomentJS from "moment-timezone";
import { GetQuotationProductListing } from "@/core/lib/inventory.lib.js";

// import DatePicker from "@/view/components/DatePicker2";
// import PriceInput from "@/view/components/PriceInput";

export default {
	name: "inventory-detail",
	title: "Detail Perishables",
	data() {
		return {
			/* inventoryTab: null, */
			uuid: null,
			barcode: null,
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			quotationListing: null,
			checkoutDialog: false,
			quotationListingPage: 1,
			retireDialog: false,
			contentLoaded: false,
			retireStockDialog: false,
			reservationDialog: false,
			inventory: {},
			deviceWidth: document.documentElement.clientWidth,
			product: {},
			// priceList: [],
			// savePriceDialog: false,
			// formValid: true,
			pageLoading: false,
			outSideSaveDialog: false,
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "product-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "inventory-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
				case "retire-stock":
					this.retireStockDialog = true;
					break;
				case "add-stock":
					EventBus.$emit("add:stock", { type: "inventory", typeUUID: this.uuid });
					break;
				case "stock-history":
					break;
				case "print-label":
					break;
				case "checkout-history":
					break;
				case "verification-history":
					break;
				case "reserve":
					this.reservationDialog = true;
					break;
				case "checkout":
					this.checkoutDialog = true;
					break;
			}
		},
		getInventory() {
			GetInventory(this.uuid)
				.then((data) => {
					// this.barcode = data.barcode;
					// this.inventory = data;
					// this.deleteText = `#${data.barcode} - ${data.name}`;
					// this.deleteURL = `inventory/${data.uuid}`;
					this.product = {
						barcode: data.barcode,
						type: data.product_type,
						name: data.name,
						available_stock: data.available_stock,
						serial_number: data.serial_number,
						quote_ref_no: data.quote_ref_no,
						description: data.description,
						material_standard: data.material_standard,
						dimension_standard: data.diamension_standard,
						reorder_level: data.reorder_quantity,
						unit: data.unit,
						image: data.image,
						manufacturer: data.manufacturer,
						supplier_relation: data.supplier_relation ? data.supplier_relation : [],
						pricing_logs: data.pricing_logs,
						pricing_logs_history: data.pricing_logs_history,
						category: data.group_relation.name,
						initial_stock: data.initial_stock,
						unit_price_currency: data.unit_price_currency,
						unit_price: data.unit_price,
						selling_cost: data.selling_cost,
						discount_price: data.discount_price,
						effective_from: data.effective_from,
						weight: data.weight,
						height: data.height,
						width: data.width,
						diameter: data.diameter,
						reduced_diameter: data.reduced_diameter,
						length: data.length,
						weight_unit: data.weight_unit,
						diameter_unit: data.diameter_unit,
						reduced_diameter_unit: data.reduced_diameter_unit,
						length_unit: data.length_unit,
						height_unit: data.height_unit,
						width_unit: data.width_unit,
						added_at: data.added_at,
						added_by: data.added_by,
						updated_at: data.updated_at,
						updated_by: data.updated_by,
					};
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Product", disabled: false, href: "/product" },
						// { text: "Detail", disabled: false },
						{ text: data.barcode, disabled: false, href: "#" },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		async getProductQuotation(uuid, page) {
			this.quotationListingPage = page;

			const data = await GetQuotationProductListing(uuid, this.quotationListingPage);
			this.quotationListing = data;
		},
		closeDialog() {
			this.savePriceDialog = false;
		},
		openPriceDialog() {
			// savePriceDialog
			this.outSideSaveDialog = true;
		},
		MomentJS,
	},
	computed: {
		inventoryTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.inventoryTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "detail";
			},
		},
	},
	components: {
		Files,
		Comments,
		ProductPOMomentHistory,
		// TooltipQuestion,
		QuantityLocation,
		ReservationListing,
		TransactionListing,
		EventListing,
		ProductQuotationListing,
		SupplierListing,
		WorkOrderListing,
		ImageTemplate,
		CheckoutTemplate,
		ShowValue,
		ShowPrice,
		ItemRetirementsListing,
		AddStockTemplate,
		DeleteTemplate,
		RetireTemplate,
		RetireStockTemplate,
		ReservationTemplate,
		RelatedServiceTemplate,
		SupportTemplate,
		Barcode,
		ShowBarcode,
		ProductPriceListing,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Product", disabled: true, href: "/product" },
			{ text: "Detail", disabled: true, href: "#" },
		]);

		const { uuid } = this.$route.params;
		if (uuid) {
			this.getProductQuotation(uuid, this.quotationListingPage);
		}
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}

		this.getInventory();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
