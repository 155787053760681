<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<v-icon color="blue darken-4">mdi-account-group</v-icon>
					<!--end::Svg Icon-->
				</span>
				Suppliers
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)" v-if="!pageLoading">
			<template v-if="supplers.length">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="50" class="p-2 blue lighten-4 text-center">#</th>
							<th class="p-2 blue lighten-4">Name</th>
							<th class="p-2 blue lighten-4">Supplier Code</th>
							<th class="p-2 blue lighten-4 text-center">Price</th>
							<th class="p-2 blue lighten-4 text-center">Pricing History</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in supplers" :key="index">
							<td class="p-2 border-top-light-grey text-center">
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey">
								<!-- {{ row.display_name }} -->
								<ShowValue :object="row" object-key="display_name" label="display name"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowValue :object="row" object-key="part_number" label="supplier code"></ShowValue>
							</td>
							<td class="p-2 border-top-light-grey text-center">
								<v-chip label>
									{{ formatMoney(row.price) }}
								</v-chip>
							</td>
							<td class="p-2 border-top-light-grey text-center">
								<router-link
									:to="{
										name: 'purchase-order-supplier-history',
										params: { uuid: $route.params.uuid, supplier_id: row?.supplier },
									}"
								>
									<v-btn class="blue darken-4 white--text ml-2" dense small> View </v-btn>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</template>
			<p v-else class="m-0 row-not-found text-center">
				<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
				Uhh... There are no supplier at the moment.
			</p>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
// import ImageTemplate from "@/view/components/Image";

export default {
	name: "SupplierListing",
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			supplers: [],
			pageLoading: true,
		};
	},
	methods: {
		getSupplier() {
			ApiService.get(`${this.type}/${this.typeUuid}/suppliers`)
				.then(({ data }) => {
					this.supplers = data.data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
	},
	mounted() {
		this.getSupplier();
	},
};
</script>
