<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<v-icon color="blue darken-4">mdi-receipt</v-icon>
					<!--end::Svg Icon-->
				</span>
				Quotation
			</v-flex>
			<v-spacer></v-spacer>
		</v-layout>
		<div class="overflow-y" style="min-height: calc(100vh - 520px)" v-if="!pageLoading">
			<template>
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="50" class="p-2 blue lighten-4 text-center">#</th>
							<th class="p-2 blue lighten-4">Quotation ID</th>
							<th class="p-2 blue lighten-4">Actual Price</th>
							<th class="p-2 blue lighten-4">Quote Price</th>
							<th class="p-2 blue lighten-4">Status</th>
						</tr>
					</thead>
					<tbody v-if="quoteListing.length">
						<tr v-for="(row, index) in quoteListing" :key="index">
							<td class="p-2 border-top-light-grey text-center">
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey text-left">
								<Chip
									v-if="row.quotation_barcode"
									tooltip
									tooltip-text="quotation #"
									small
									outlined
									:label="true"
									color="cyan"
									custom-class="mb-1 mr-1 white--text px-2"
									:text="row.quotation_barcode"
								></Chip>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowPrice :object="row" object-key="actual_price" label="Actual Price"></ShowPrice>
							</td>
							<td class="p-2 border-top-light-grey">
								<ShowPrice :object="row" object-key="quote_price" label="quote price"></ShowPrice>
							</td>
							<td class="p-2 border-top-light-grey">
								<Chip
									v-if="row.quotation_barcode"
									tooltip
									tooltip-text="status"
									small
									outlined
									:label="true"
									:color="getStatusColor('Approved')"
									custom-class="mb-1 mr-1 white--text px-2"
									text="Approved"
								></Chip>
							</td>
						</tr>
					</tbody>

					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no quotation at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</template>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<v-row v-if="totalPage" class="my-2">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="updatePagination(page)"
				></v-pagination>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import ShowPrice from "@/view/components/ShowPrice";
// import { cloneDeep } from "lodash";
// import moment from "moment";
import Chip from "@/view/components/Chip";

// import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import ImageTemplate from "@/view/components/Image";
export default {
	name: "ProductPriceListing",
	props: {
		quotationListing: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		quotationListing: {
			deep: true,
			handler() {
				this.init();
			},
		},
	},
	data() {
		return {
			pageLoading: false,
			quoteListing: [],
			page: 1,
			showingString: null,
			totalPage: 0,
		};
	},
	methods: {
		updatePagination(page) {
			this.$emit("update-product", page);
		},
		init() {
			if (this.quotationListing) {
				const { tbody, current_page, total_page } = this.quotationListing;
				this.quoteListing = tbody;
				this.page = current_page;
				this.totalPage = total_page;
				this.showingString = this.quotationListing.showing_string;
			}
		},
	},
	components: {
		ShowPrice,
		Chip,
	},
	mounted() {
		// if (this.quotationListing) {
		// 	const { tbody, current_page, total_page } = this.quotationListing;
		// 	this.quoteListing = tbody;
		// 	this.page = current_page;
		// 	this.totalPage = total_page;
		// 	this.showingString = this.quotationListing.showing_string;
		// }
		this.init();
	},
};
</script>
